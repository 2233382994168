import React from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

interface Props {
  title: string
  description?: string
  keywords?: string
  twitterHandle?: string
  image?: string
}

const Seo = (props?: Props) => (
  <div style={{ display: "none" }}>
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
              description
              keywords
              twitterHandle
              image
            }
          }
        }
      `}
      render={({ site }) => {
        const siteMetadata = {
          ...site.siteMetadata,
          ...props,
        }

        const { title, slug, description, keywords, twitterHandle, image } = siteMetadata

        let slugURL

        if (slug === "home") {
          slugURL = ""
        } else {
          slugURL = slug
        }

        return (
          <Helmet
            htmlAttributes={{
              lang: "en",
            }}
            title={title}
            meta={[
              { name: "description", content: description },
              { name: "keywords", content: keywords },

              { name: "twitter:card", content: "summary_large_image" },
              { name: "twitter:site", content: twitterHandle },
              { name: "twitter:creator", content: twitterHandle },
              { name: "twitter:title", content: title },
              {
                name: "twitter:image",
                content:
                  image ||
                  "https://images.ctfassets.net/l7neci24wkw8/6MAbbajLXCxOrsqtzZDqQw/e638e99ebd9fdb9e1727ca0c4109bdc9/HomePageSEO.png",
              },

              { name: "description", content: description },
              { name: "keywords", content: keywords },
              { name: "application-name", content: "&nbsp;" },

              { property: "og:title", id: "fbtitle", content: title },
              {
                property: "og:image",
                id: "fbimage",
                content:
                  image ||
                  "https://images.ctfassets.net/l7neci24wkw8/6MAbbajLXCxOrsqtzZDqQw/e638e99ebd9fdb9e1727ca0c4109bdc9/HomePageSEO.png",
              },

              {
                property: "og:url",
                id: "ogurl",
                content: `http://angle.sh/${slugURL}`,
              },
              { property: "og:type", id: "ogtype", content: "website" },
              { property: "og:title", id: "ogtitle", content: title },
              {
                property: "og:description",
                id: "ogdescription",
                content: description,
              },
              {
                property: "og:image",
                id: "ogimage",
                content:
                  image ||
                  "https://images.ctfassets.net/l7neci24wkw8/6MAbbajLXCxOrsqtzZDqQw/e638e99ebd9fdb9e1727ca0c4109bdc9/HomePageSEO.png",
              },
            ]}
          />
        )
      }}
    />
  </div>
)

export default Seo
