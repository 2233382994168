import React, { useState, useRef } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import MenuRow from "../rows/MenuRow"
import { mobileData } from "../../data/HeaderData"
import { themes } from "../styles/ColorStyles"
import { useShowModal } from "../../states/context/ModalContext"
import { useClickAway } from "react-use"
import useKeyPress from "../../utils/hooks/useKeyPress"
import useAuth from "../../states/context/AuthContext"

interface Props {
  isOpen?: boolean
}

const MobileHeader = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { isUserLoggedIn, signOut } = useAuth()
  const showSignInModal = useShowModal("signIn")

  if (useKeyPress("escape") && isOpen) {
    setIsOpen(false)
  }

  const ref = useRef(null)
  useClickAway(ref, () => {
    setIsOpen(false)
  })

  return (
    <Wrapper ref={ref}>
      <Button onClick={() => setIsOpen(!isOpen)}>
        <Icon src="/images/icons/hamburger-menu.svg" alt="hamburger icon" />
      </Button>
      <TooltipWrapper isOpen={isOpen}>
        {mobileData.map((data, index) => (
          <div key={index}>
            <MenuRow {...data} />
            <Separator className="separator" />
          </div>
        ))}
        {isUserLoggedIn ? (
          <MenuRow title="Account" onClick={() => navigate("/account")} />
        ) : (
          <MenuRow title="Sign In" onClick={showSignInModal} />
        )}
        {isUserLoggedIn && (
          <>
            <Separator />
            <MenuRow title="Sign out" onClick={signOut} />
          </>
        )}
      </TooltipWrapper>
    </Wrapper>
  )
}

export default MobileHeader

const Wrapper = styled.div``

const Button = styled.div`
  position: relative;
  display: grid;
  justify-content: center;
  width: 44px;
  height: 44px;
  right: 0;
  border-radius: 50%;
  background: ${themes.dark.card.backgroundColor};
  box-shadow: ${themes.dark.card.boxShadow};
  backdrop-filter: blur(40px);
  cursor: pointer;
`

const Icon = styled.img`
  position: relative;
  width: 24px;
  height: 24px;
  margin: 0;
  top: 10px;
`

const TooltipWrapper = styled.div<Props>`
  position: absolute;
  max-width: 260px;
  width: 100%;
  margin-top: 10px;
  padding: 20px;
  right: 0px;
  background: ${themes.dark.card.backgroundColor};
  box-shadow: ${themes.dark.card.boxShadow};
  backdrop-filter: blur(40px) brightness(80%) saturate(150%);
  border-radius: 20px;
  transition: 0.3s ease-in-out;
  visibility: ${props => (props.isOpen ? "visible" : "hidden")};
  opacity: ${props => (props.isOpen ? "1" : "0")};
  transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
  transform-origin: top center;
  transform: ${props =>
    props.isOpen ? "rotateX(0deg) translateY(0px)" : "rotateX(-20deg) translateY(-5px)"};

  @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    transform: ${props =>
      props.isOpen
        ? "skewY(0deg) rotate(0deg) translateY(0px)"
        : "skewY(-5deg) rotate(5deg) translateY(-30px)"};
  }
`

const Separator = styled.div`
  max-width: 180px;
  width: 100%;
  height: 1px;
  background: #ffffff;
  opacity: 0.2;
`
