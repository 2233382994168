export const headerData = [
  {
    title: "Pricing",
    link: "pricing",
  },
  {
    title: "Mockups",
    link: "mockups",
  },
  {
    title: "Tutorials",
    link: "tutorials",
  },
  {
    title: "Updates",
    link: "updates",
  },
  {
    title: "Downloads",
    link: "downloads",
  },
]

export const mobileData = [
  {
    title: "Pricing",
    link: "pricing",
  },
  {
    title: "Mockups",
    link: "mockups",
  },
  {
    title: "Tutorials",
    link: "tutorials",
  },
  {
    title: "Updates",
    link: "updates",
  },
  {
    title: "Downloads",
    link: "downloads",
  },
]

export const tooltip1 = [
  {
    title: "Updates",
    link: "updates",
  },
  {
    title: "Downloads",
    link: "downloads",
  },
]

export const tooltip2 = [
  {
    title: "Tutorials",
    link: "tutorials",
  },
  {
    title: "Updates",
    link: "updates",
  },
  {
    title: "Downloads",
    link: "downloads",
  },
]

export const tooltip3 = [
  {
    title: "Mockups",
    link: "mockups",
  },
  {
    title: "Tutorials",
    link: "tutorials",
  },
  {
    title: "Updates",
    link: "updates",
  },
  {
    title: "Downloads",
    link: "downloads",
  },
]

export const footerData = [
  {
    title: "Pricing",
    link: "pricing",
  },
  {
    title: "Mockups",
    link: "mockups",
  },
  {
    title: "Tutorials",
    link: "tutorials",
  },
  {
    title: "Updates",
    link: "updates",
  },
]
