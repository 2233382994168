import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import * as TextStyles from "../styles/TextStyles"

interface Props {
  title?: string
  link?: string
  onClick?: () => void
}

const NavigationButton = (props: Props) => {
  const { title, link, onClick } = props

  return link ? (
    <MyLink to={`/${link}`}>
      <Wrapper>
        <Title className="navigation-title">{title}</Title>
      </Wrapper>
    </MyLink>
  ) : (
    <Wrapper onClick={onClick}>
      <Title className="navigation-title">{title}</Title>
    </Wrapper>
  )
}

export default NavigationButton

const Wrapper = styled.div`
  width: fit-content;
  height: fit-content;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 20px;
  border: 0.5px solid rgba(255, 255, 255, 0);
  cursor: pointer;
  transition: 0.3s ease-in-out;

  :hover {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    border: 0.5px solid rgba(255, 255, 255, 0.2);
  }
`

const Title = styled(TextStyles.Caption)``

const MyLink = styled(Link)`
  width: fit-content;
  text-decoration: none;
`
