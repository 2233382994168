import React from "react"
import styled from "styled-components"
import { Link, navigate } from "gatsby"

import * as TextStyles from "../styles/TextStyles"
import NavigationButton from "../buttons/NavigationButton"
import { useShowModal } from "../../states/context/ModalContext"
import { footerData } from "../../data/HeaderData"
import NewsletterSection from "../sections/NewsletterSection"
import useAuth from "../../states/context/AuthContext"

const Footer = () => {
  const showSignInModal = useShowModal("signIn")
  const { isUserLoggedIn } = useAuth()

  return (
    <Wrapper>
      <ContentWrapper>
        <Container>
          <LinkWrapper>
            <NavigationButton title="Home" onClick={() => navigate("/")} />
            {footerData.map((data, index) => (
              <NavigationButton key={index} {...data} />
            ))}
            {isUserLoggedIn ? (
              <NavigationButton title="Account" onClick={() => navigate("/account")} />
            ) : (
              <NavigationButton title="Sign In" onClick={showSignInModal} />
            )}
          </LinkWrapper>
          <MediumText>
            Made with love by the team behind <a href="https://designcode.io">Design+Code</a>
          </MediumText>
          <Caption>
            2022 © <MyLink to="/terms">Terms of Service</MyLink> -
            <MyLink to="/privacy"> Privacy Policy</MyLink>
          </Caption>
        </Container>
        <NewsletterSection />
      </ContentWrapper>
    </Wrapper>
  )
}

export default Footer

const Wrapper = styled.div`
  position: relative;
  background: #1f1144;
  width: 100%;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 50px 20px;

  @media (max-width: 860px) {
    text-align: center;
  }
`

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 360px 467px;
  justify-content: space-between;
  position: relative;
  max-width: 890px;
  margin: 0 auto;

  @media (max-width: 860px) {
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 20px;
  }
`

const Container = styled.div`
  max-width: 360px;
  display: grid;
  gap: 12px;
`

const LinkWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 4px;

  .navigation-title {
    color: #ffffff;
  }

  @media (max-width: 860px) {
    justify-items: center;
  }
`

const MediumText = styled(TextStyles.MediumText)`
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);

  a {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.5);

    :hover {
      text-decoration: underline;
    }
  }
`

const Caption = styled(TextStyles.Caption)`
  font-size: 14px;
  color: #6c6a8e;
`

const MyLink = styled(Link)`
  text-decoration: none;
  color: #6c6a8e;
`
