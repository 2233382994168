import React from "react"
import styled from "styled-components"

import NewsletterForm from "../forms/NewsletterForm"
import * as TextStyles from "../styles/TextStyles"

const Newsletter = () => {
  return (
    <Wrapper>
      <TextWrapper>
        <Icon src="/images/icons/email.svg" alt="email icon" />
        <Caption>
          Subscribe to receive updates about new devices. Max. 1 email per month. No spam.
        </Caption>
      </TextWrapper>
      <NewsletterForm />
    </Wrapper>
  )
}

export default Newsletter

const Wrapper = styled.div`
  padding: 0 20px;
`

const TextWrapper = styled.div`
  display: grid;
  max-width: 340px;
  grid-template-columns: auto auto;
  align-items: center;
  gap: 12px;

  @media (max-width: 860px) {
    width: fit-content;
    margin: 0 auto;
  }
`

const Icon = styled.img`
  width: fit-content;
  height: fit-content;
  margin: auto;
`

const Caption = styled(TextStyles.Caption)`
  text-align: left;
  color: #ffffff;
  margin: 0px;
`
