import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import * as TextStyles from "../styles/TextStyles"

interface Props {
  title: string
  link?: string
  onClick?: () => void
}

const MenuRow = (props: Props) => {
  const { title, link, onClick } = props

  return link ? (
    <MyLink to={`/${link}`}>
      <Wrapper>
        <Title>{title}</Title>
      </Wrapper>
    </MyLink>
  ) : (
    <Wrapper onClick={onClick}>
      <Title>{title}</Title>
    </Wrapper>
  )
}

export default MenuRow

const Wrapper = styled.div`
  height: 44px;
  transition: 0.3s ease-in-out;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;

  :hover {
    background: rgba(255, 255, 255, 0.1);
    background-blend-mode: overlay;
    mix-blend-mode: normal;
    box-sizing: border-box;
    box-shadow: inset 0 0 0 0.5px rgba(255, 255, 255, 0.2);
  }
`

const Title = styled(TextStyles.Caption)`
  font-weight: normal;
  line-height: 130%;
  color: #ffffff;
  text-align: center;
  margin: auto;
`

const MyLink = styled(Link)`
  width: fit-content;
  text-decoration: none;
`
