import React from "react"
import { createGlobalStyle } from "styled-components"

import Header from "./Header"
import Footer from "./Footer"
import Seo from "./Seo"
import { MetadataReadonly } from "../../types/contentfulTypes"
import "./layout.css"
import { themes } from "../styles/ColorStyles"
// import * as TextStyles from "../styles/TextStyles"

interface Props {
  children: any
  contentfulMetadata: MetadataReadonly
}

const GlobalStyle = createGlobalStyle`
  body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: ${themes.light.backgroundColor};
  
     @media (prefers-color-scheme: dark) {
  background: ${themes.dark.backgroundColor};
    }
  }
`

const Layout = (props: Props) => {
  const { children, contentfulMetadata } = props

  return (
    <>
      {/* <DiscountBanner>
        <DiscountText>Get 50% off during Black Friday!</DiscountText>
      </DiscountBanner> */}
      <Seo {...contentfulMetadata} />
      <GlobalStyle />
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout

// const DiscountBanner = styled.div`
//    width: 100%;
//    text-align: center;
//    background: #000000;
//    padding: 12px 0;
//  `

//  const DiscountText = styled(TextStyles.Caption)`
//    color: #ffffff;
//    margin: 0 auto;

//    @media (prefers-color-scheme: dark) {
//      color: #ffffff;
//    }
//  `