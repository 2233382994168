import React, { useEffect, useState } from "react"
import styled from "styled-components"

import addToMailchimp from "gatsby-plugin-mailchimp"
import { useShowModal } from "../../states/context/ModalContext"
import useInput from "../../utils/hooks/useInput"

const NewsletterForm = () => {
  const { value: email, bind: bindEmail } = useInput("")
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)

  const showSuccessModal = useShowModal("message", {
    logLevel: "success",
    message:
      "Almost finished... We need to confirm your email address. To complete the subscription process, please click the link in the email we just sent you.",
  })
  const showErrorModal = useShowModal("message", { logLevel: "error", message: error })

  useEffect(() => {
    success && showSuccessModal()
    error && showErrorModal()
  }, [success, error])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setError("")
    setSuccess(false)

    const response = await addToMailchimp(email)

    if (response.result === "success") {
      setSuccess(true)
    } else if (response.result === "error") {
      if (response.msg.includes("is already subscribed")) {
        setError("You are already subscribed to this list.")
      } else {
        setError(
          "An error occured, please try again later or contact support at angle@designcode.io"
        )
      }
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <label>
        <Wrapper>
          <InputContainer>
            <Icon src="/images/icons/email-black.svg" alt="icon" />
            <Input
              name="email"
              type="email"
              placeholder="Email address"
              autoComplete="on"
              {...bindEmail}
              required
            />
          </InputContainer>
          <Button>Subscribe</Button>
        </Wrapper>
      </label>
    </form>
  )
}

export default NewsletterForm

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 174px;
  gap: 20px;
  max-width: 467px;
  height: 40px;
  margin: 20px auto 0px;

  @media (max-width: 420px) {
    grid-template-columns: 1fr;
  }
`

const InputContainer = styled.div`
  position: relative;
`

const Icon = styled.img`
  position: absolute;
  width: 24px;
  height: 24px;
  top: 7px;
  left: 8px;
  margin: 0;
`

const Input = styled.input`
  width: 100%;
  height: 40px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.53);
  box-shadow: 0px 30px 30px rgba(69, 42, 124, 0.15);
  transition: all 0.3s ease-in;
  padding: 0px 8px 0px 44px;

  font-size: 14px;
  font-weight: normal;
  line-height: 17px;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #000000;
    opacity: 0.5;
  }

  :focus {
    outline: none;
  }
`

const Button = styled.button`
  width: 100%;
  height: 40px;
  padding: 10px 40px;
  background: none;
  border: 2px solid #8338a8;
  box-sizing: border-box;
  filter: drop-shadow(0px 10px 20px rgba(69, 42, 124, 0.15));
  border-radius: 12px;
  cursor: pointer;
  margin: 0;

  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 130%;
  text-transform: uppercase;
  color: #8338a8;

  &,
  * {
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  :hover {
    background: #8338a8;
    transform: scale(1.05);
    color: #ffffff;
  }
`
