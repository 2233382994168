import React, { useState, useRef } from "react"
import styled from "styled-components"

import NavigationButton from "../buttons/NavigationButton"
import { themes } from "../styles/ColorStyles"
import useWindowSize from "../../utils/hooks/useWindowSize"
import { useClickAway } from "react-use"
import { tooltip1, tooltip2, tooltip3 } from "../../data/HeaderData"
import MenuRow from "../rows/MenuRow"
import useKeyPress from "../../utils/hooks/useKeyPress"

interface Props {
  isOpen?: boolean
}

const HeaderTooltip = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { width } = useWindowSize()

  const handleData = () => {
    if (width < 1024 && width >= 900) {
      return tooltip1
    }
    if (width < 900 && width >= 768) {
      return tooltip2
    }
    if (width < 768 && width >= 550) {
      return tooltip3
    }
  }

  if (useKeyPress("escape") && isOpen) {
    setIsOpen(false)
  }

  const ref = useRef(null)
  useClickAway(ref, () => {
    setIsOpen(false)
  })

  return (
    <Wrapper ref={ref}>
      <NavigationButton title="More" onClick={() => setIsOpen(!isOpen)} />
      <TooltipWrapper isOpen={isOpen}>
        {handleData()?.map((item, index) => (
          <div key={index}>
            <MenuRow {...item} />
            <Separator className="separator" />
          </div>
        ))}
      </TooltipWrapper>
    </Wrapper>
  )
}

export default HeaderTooltip

const Wrapper = styled.div``

const TooltipWrapper = styled.div<Props>`
  position: absolute;
  max-width: 260px;
  margin-top: 10px;
  padding: 20px 20px;
  background: ${themes.dark.card.backgroundColor};
  box-shadow: ${themes.dark.card.boxShadow};
  backdrop-filter: blur(40px) brightness(80%) saturate(150%);
  border-radius: 20px;
  transition: 0.3s ease-in-out;
  visibility: ${props => (props.isOpen ? "visible" : "hidden")};
  opacity: ${props => (props.isOpen ? "1" : "0")};
  transition: 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
  transform-origin: top center;
  transform: ${props =>
    props.isOpen ? "rotateX(0deg) translateY(0px)" : "rotateX(-20deg) translateY(-5px)"};

  @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    transform: ${props =>
      props.isOpen
        ? "skewY(0deg) rotate(0deg) translateY(0px)"
        : "skewY(-5deg) rotate(5deg) translateY(-30px)"};
  }

  div:last-child {
    .separator:last-child {
      opacity: 0;
    }
  }
`

const Separator = styled.div`
  width: 180px;
  height: 1px;
  background: #ffffff;
  opacity: 0.2;
`
