import React from "react"
import { Link, navigate } from "gatsby"
import styled from "styled-components"

import { useShowModal } from "../../states/context/ModalContext"
import NavigationButton from "../buttons/NavigationButton"
import { headerData } from "../../data/HeaderData"
import HeaderTooltip from "../tooltips/HeaderTooltip"
import MobileHeader from "./MobileHeader"
import useAuth from "../../states/context/AuthContext"
import useWindowSize from "../../utils/hooks/useWindowSize"

const Header = () => {
  const showSignInModal = useShowModal("signIn")
  const { isUserLoggedIn } = useAuth()
  const { width } = useWindowSize()

  return (
    <Wrapper>
      <Link to="/">
        <Logo src="/images/logos/logo.svg" alt="Angle logo" />
      </Link>
      <Container>
        {headerData.map((data, index) => (
          <NavigationButton key={index} {...data} />
        ))}
        {!(width > 1024) && <HeaderTooltip />}
        {isUserLoggedIn ? (
          <NavigationButton title="Account" onClick={() => navigate("/account")} />
        ) : (
          <NavigationButton title="Sign In" onClick={showSignInModal} />
        )}
      </Container>
      <MobileWrapper>
        <MobileHeader />
      </MobileWrapper>
    </Wrapper>
  )
}

export default Header

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1234px;
  height: 44px;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 60px 40px;
  z-index: 3;
`

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(6, auto);
  gap: 50px;

  @media (max-width: 550px) {
    display: none;
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(5, auto);
    a:nth-child(4) {
      display: none;
    }
    a:nth-child(5) {
      display: none;
    }
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(4, auto);
    a:nth-child(3) {
      display: none;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, auto);
    a:nth-child(2) {
      display: none;
    }
  }
`

const Logo = styled.img`
  width: 32px;
  height: 32px;
  margin: auto;
`

const MobileWrapper = styled.div`
  display: none;

  @media (max-width: 550px) {
    display: block;
  }
`
